<template>
  <div>
    <!-- 一、工地工作时间段设置 -->
    <div class="paragraph">
      <div class="right_title">
        一、 工地工作时间段设置<span class="colorGray"
          >(开启后，使用新的工作时间段，关闭后默认8点-18点)</span
        >
      </div>
      <div>
        有效工作时间段设置<span class="colorGray"
          >(开启后，使用新的工作时间段，关闭后默认8点18点)</span
        >
      </div>
      <!-- 有效工作时间 -->
      <div>
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_one1"
          @change="onChangeSwitch_one1"
        />
        有效工作时间
        <a-select
          show-search
          v-model="placeholder_one_start"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_one_start"
          @change="handleChange_one_start"
          :disabled="disabled"
        >
          <!--    @focus="handleFocus_one_start"
          @blur="handleBlur_one_start" -->
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >点-
        <a-select
          show-search
          v-model="placeholder_one_end"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_one_end"
          @change="handleChange_one_end"
          :disabled="disabled"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >点
      </div>
    </div>
    <!-- 二、累计时长设置 -->
    <div class="paragraph">
      <div class="right_title">
        二、 累计时长设置<span class="colorGray">(设置成功后，立即生效)</span>
      </div>
      <!-- 正常使用时长 -->
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_two1"
          @change="onChangeSwitch_two1"
        />
        正常使用时长：全天累计时长大于等于
        <a-select
          show-search
          v-model="placeholder_two1"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_two1"
          @change="handleChange_two1"
          :disabled="disabled"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >小时，即为正常有效时长；<span class="colorGray"
          >(关闭后，默认2小时)</span
        >
      </div>
      <!-- 有效使用时长 -->
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_two2"
          @change="onChangeSwitch_two2"
        />
        有效使用时长：有效工作时间内累计大于等于
        <a-select
          show-search
          v-model="placeholder_two2"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_two2"
          @change="handleChange_two2"
          :disabled="disabled"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >小时，即为有效使用时长；<span class="colorGray"
          >(关闭后，默认2小时)</span
        >
      </div>
      <!-- 长期在线时长 -->
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_two3"
          @change="onChangeSwitch_two3"
        />
        长期在线时长：全天累计时长大于等于
        <a-select
          show-search
          v-model="placeholder_two3"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_two3"
          @change="handleChange_two3"
          :disabled="disabled"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >小时，即为长期在线时长；<span class="colorGray"
          >(关闭后，默认23小时)</span
        >
      </div>
      <!--  在线率低设备 -->
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_two4"
          @change="onChangeSwitch_two4"
        />
        在线率低设备：有效工作时间设备累计在线时长小于
        <a-select
          show-search
          v-model="placeholder_two4"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_two4"
          @change="handleChange_two4"
          :disabled="disabled"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >小时，即为在线率低设备；<span class="colorGray"
          >(关闭后，默认2小时)</span
        >
      </div>
    </div>
    <!-- 三、工地形象识别设置 -->
    <div class="paragraph">
      <div class="right_title">
        三、工地形象识别设置<span class="colorGray"
          >(设置成功后，立即生效)</span
        >
      </div>
      <div>
        整洁度优、良、差、摄像头歪曲拍照时间设置，抓拍时间必须间隔30分钟
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_three1"
          @change="onChangeSwitch_three1"
        />
        抓拍时间1
        <a-select
          show-search
          v-model="placeholder_three1"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three1"
          @change="handleChange_three1"
          :disabled="disabled"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >点
        <a-select
          show-search
          v-model="placeholder_three2"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three2"
          @change="handleChange_three2"
          :disabled="disabled"
        >
          <a-select-option v-for="item in 60" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >分
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_three2"
          @change="onChangeSwitch_three2"
        />
        抓拍时间2
        <a-select
          show-search
          v-model="placeholder_three3"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three3"
          @change="handleChange_three3"
          :disabled="disabled"
        >
          <a-select-option
            v-for="item in option_three3"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >点
        <a-select
          show-search
          v-model="placeholder_three4"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three4"
          @change="handleChange_three4"
          :disabled="disabled"
        >
          <a-select-option
            v-for="item in option_three4"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >分
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_three3"
          @change="onChangeSwitch_three3"
        />
        抓拍时间3
        <a-select
          show-search
          v-model="placeholder_three5"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three5"
          @change="handleChange_three5"
          :disabled="disabled"
        >
          <a-select-option
            v-for="item in option_three5"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >点
        <a-select
          show-search
          v-model="placeholder_three6"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three6"
          @change="handleChange_three6"
          :disabled="disabled"
        >
          <a-select-option
            v-for="item in option_three6"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >分
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_three4"
          @change="onChangeSwitch_three4"
        />

        抓拍时间4
        <a-select
          show-search
          v-model="placeholder_three7"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three7"
          @change="handleChange_three7"
          :disabled="disabled"
        >
          <a-select-option
            v-for="item in option_three7"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >点
        <a-select
          show-search
          v-model="placeholder_three8"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three8"
          @change="handleChange_three8"
          :disabled="disabled"
        >
          <a-select-option
            v-for="item in option_three8"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >分
      </div>
    </div>
    <!-- 四、考勤打卡管理 -->
    <div class="paragraph">
      <div class="right_title">
        四、考勤打卡管理<span class="colorGray">(设置成功后，立即生效)</span>
      </div>
      <div>
        无感打卡<span class="colorGray"
          >(建议开启，降低打卡播报频率，以免打扰施工者和邻居)</span
        >
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_four1"
          @change="onChangeSwitch_four1"
        />
        同一个设备、同一个人首次打卡后，需要隔
        <a-select
          show-search
          v-model="placeholder_four1"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_four1"
          @change="handleChange_four1"
          :disabled="disabled"
        >
          <a-select-option v-for="item in 60" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option>
        </a-select>
        <a-select
          show-search
          v-model="placeholder_four2"
          option-filter-prop="children"
          style="width: 75px"
          :filter-option="filterOption_four2"
          @change="handleChange_four2"
          :disabled="disabled"
        >
          <a-select-option
            v-for="(item, index) in selectHorS"
            :key="index"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >再次打卡才播报打卡结果。*注，不影响正常打卡，仅仅是不在语音播报
        <div class="bgcGray paragraph_margin padding">仅对工人打卡生效</div>
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_four2"
          @change="onChangeSwitch_four2"
        />
        同一个设备、同一个人首次打卡后，需要隔
        <a-select
          show-search
          v-model="placeholder_four3"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_four3"
          @change="handleChange_four3"
          :disabled="disabled"
        >
          <a-select-option v-for="item in 60" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option>
        </a-select>
        <a-select
          show-search
          v-model="placeholder_four4"
          option-filter-prop="children"
          style="width: 75px"
          :filter-option="filterOption_four4"
          @change="handleChange_four4"
          :disabled="disabled"
        >
          <a-select-option
            v-for="(item, index) in selectHorS"
            :key="index"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >再次打卡才播报打卡结果。*注，不影响正常打卡，仅仅是不在语音播报
        <div class="bgcGray paragraph_margin padding">
          仅对项目经理、工程管家、其他岗位人员打卡生效
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetselectByBcId } from "../../api/role";

export default {
  name: "ViewSet",
  props: {
    bc_id: {
      // type: String,
      default: "",
    },
    showEdit: {
      default: false,
    },
  },
  data() {
    return {
      placeholder_one_start: "8",
      placeholder_one_end: "18",
      disabled: true,
      placeholder_two1: "2",
      placeholder_two2: "2",
      placeholder_two3: "23",
      placeholder_two4: "2",
      placeholder_three1: "7",
      placeholder_three2: "50",
      placeholder_three3: "15",
      placeholder_three4: "0",
      placeholder_three5: "",
      placeholder_three6: "",
      placeholder_three7: "",
      placeholder_three8: "",
      placeholder_four1: "4",
      placeholder_four2: "小时",
      placeholder_four3: "1",
      placeholder_four4: "分钟",
      selectHorS: ["小时", "分钟"],
      option_three3: [],
      option_three4: [],
      option_three5: [],
      option_three6: [],
      option_three7: [],
      option_three8: [],
      defaultChecked_one1: true, //有效工作时间开关
      defaultChecked_two1: true, //正常使用时长
      defaultChecked_two2: true, //有效使用时长
      defaultChecked_two3: true, //长期在线时长
      defaultChecked_two4: false, //在线率低
      defaultChecked_three1: true, //抓拍时间1
      defaultChecked_three2: true, //抓拍时间2
      defaultChecked_three3: false, //抓拍时间3
      defaultChecked_three4: false, //抓拍时间4
      defaultChecked_four1: true, //工人
      defaultChecked_four2: false, //其他
      disabled_switch: true,
    };
  },
  created() {
    // 今天0点时间戳
    let today = new Date(new Date().toLocaleDateString()).getTime() / 1000;
    // 处理抓拍时间2的小时分钟 可选区间
    // 抓拍时间1的时间戳
    let time1 =
      today + this.placeholder_three1 * 3600 + this.placeholder_three2 * 60;
    // 抓拍时间必须间隔30分钟   30*60=1800
    // 抓拍时间1 + 30分钟 的时间
    let time2_lowest = this.$Format(time1 + 1800).split(" ")[1];
    let time2_lowest_H = time2_lowest.split(":")[0];
    let time2_lowest_M = time2_lowest.split(":")[1] * 1 + 1;
    // 抓拍时间2小时数 可选择数组
    for (let item = 0; item < 24; item++) {
      if (item >= time2_lowest_H) {
        this.option_three3.push(item);
      }
    }
    // 抓拍时间2分钟数 可选择数组
    for (let item = 0; item < 60; item++) {
      if (item >= time2_lowest_M) {
        this.option_three4.push(item);
      }
    }
    // 处理抓拍时间3的小时分钟 可选区间
    // 抓拍时间2的时间戳
    let time2 =
      today + this.placeholder_three3 * 3600 + this.placeholder_three4 * 60;
    let time3_lowest = this.$Format(time2 + 1800).split(" ")[1];
    let time3_lowest_H = time3_lowest.split(":")[0];
    let time3_lowest_M = time3_lowest.split(":")[1] * 1 + 1;
    for (let item = 0; item < 24; item++) {
      if (item >= time3_lowest_H) {
        this.option_three5.push(item);
      }
    }
    for (let item = 0; item < 60; item++) {
      if (item >= time3_lowest_M) {
        this.option_three6.push(item);
      }
    }
    // 处理抓拍时间4的小时分钟 可选区间
    let time3 =
      today + this.placeholder_three5 * 3600 + this.placeholder_three6 * 60;
    let time4_lowest = this.$Format(time3 + 1800).split(" ")[1];
    let time4_lowest_H = time4_lowest.split(":")[0];
    let time4_lowest_M = time4_lowest.split(":")[1] * 1 + 1;
    for (let item = 0; item < 24; item++) {
      if (item >= time4_lowest_H) {
        this.option_three7.push(item);
      }
    }
    for (let item = 0; item < 60; item++) {
      if (item >= time4_lowest_M) {
        this.option_three8.push(item);
      }
    }
  },
  mounted() {
    // 点击取消编辑时重新获取一下信息
    if (this.showEdit == false && this.bc_id) {
      this.GetselectByBcId(this.bc_id);
    }
  },
  watch: {
    bc_id(newvalue) {
      this.GetselectByBcId(newvalue);
    },
    showEdit(newValue) {},
    deep: true, // 深度监听
    immediate: true, // 第一次改变就执行
  },
  methods: {
    // 根据id获取信息
    GetselectByBcId(bc_id) {
      let param = { bcId: bc_id };
      let orderParam = ["bcId"];
      // const requestParameters = this.$order(param, orderParam);
      const params = {
        // ...requestParameters,
        ...param,
        // verify: this.$verify(requestParameters, orderParam),
        verify: this.$verify(param, orderParam),
      };
      GetselectByBcId(params).then((res) => {
        console.log("res.data", res.data);
        let data = res.data;
        // 有效工作时间
        this.defaultChecked_one1 =
          data.ssEffectiveuseSpotOnoff == "1" ? true : false;
        this.placeholder_one_start = data.ssEffectiveuseSpotBegin / 3600;
        this.placeholder_one_end = data.ssEffectiveuseSpotEnd / 3600;
        // 正常使用时长
        this.defaultChecked_two1 = data.ssNormaluseOnoff == "1" ? true : false;
        this.placeholder_two1 = data.ssNormaluse / 3600;
        // 有效使用时长开关
        this.defaultChecked_two2 =
          data.ssEffectiveuseSumOnoff == "1" ? true : false;
        this.placeholder_two2 = data.ssEffectiveuseSum / 3600;
        // 长期在线时长开关
        this.defaultChecked_two3 = data.ssLonguseOnoff == "1" ? true : false;
        this.placeholder_two3 = data.ssLonguse / 3600;
        //在线率低设备开关
        this.defaultChecked_two4 = data.ssLowuseOnoff == "1" ? true : false;
        this.placeholder_two4 = data.ssLowuse / 3600;
        // 抓拍时间1
        this.defaultChecked_three1 =
          data.ssCleanTime1Onoff == "1" ? true : false;
        this.placeholder_three1 = parseInt(data.ssCleanTime1 / 3600);
        this.placeholder_three2 = parseInt(
          ((data.ssCleanTime1 / 3600) % 1) * 60
        );
        // 抓拍时间2
        this.defaultChecked_three2 =
          data.ssCleanTime2Onoff == "1" ? true : false;
        this.placeholder_three3 = parseInt(data.ssCleanTime2 / 3600);
        this.placeholder_three4 = parseInt(
          ((data.ssCleanTime2 / 3600) % 1) * 60
        );

        // 抓拍时间3
        this.defaultChecked_three3 =
          data.ssCleanTime3Onoff == "1" ? true : false;
        this.placeholder_three5 = parseInt(data.ssCleanTime3 / 3600);
        this.placeholder_three6 = parseInt(
          ((data.ssCleanTime3 / 3600) % 1) * 60
        );
        // 抓拍时间4
        this.defaultChecked_three4 =
          data.ssCleanTime4Onoff == "1" ? true : false;
        this.placeholder_three7 = parseInt(data.ssCleanTime4 / 3600);
        this.placeholder_three8 = parseInt(
          ((data.ssCleanTime4 / 3600) % 1) * 60
        );
        // 仅对工人打卡生效开关
        this.defaultChecked_four1 =
          data.ssAtimeWorkerOnoff == "1" ? true : false;

        if (data.ssAtimeWorker / 3600 >= 1) {
          this.placeholder_four2 = "小时";
          this.placeholder_four1 = parseInt(data.ssAtimeWorker / 3600);
        } else {
          this.placeholder_four2 = "分钟";
          this.placeholder_four1 = parseInt(data.ssAtimeWorker / 60);
        }
        // 除工人外的   打卡  开关
        this.defaultChecked_four2 =
          data.ssAtimeWorkerbossOnoff == "1" ? true : false;

        if (data.ssAtimeWorkerboss / 3600 >= 1) {
          this.placeholder_four3 = parseInt(data.ssAtimeWorkerboss / 3600);
          this.placeholder_four4 = "小时";
        } else {
          this.placeholder_four3 = parseInt(data.ssAtimeWorkerboss / 60);
          this.placeholder_four4 = "分钟";
        }
      });
    },
    handleChange_one_start(value) {
      console.log(`selected ${value}`);
    },
    filterOption_one_start(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_one_end(value) {
      console.log(`selected ${value}`);
    },
    filterOption_one_end(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    handleChange_two1(value) {
      console.log(`selected ${value}`);
    },
    filterOption_two1(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_two2(value) {
      console.log(`selected ${value}`);
    },
    filterOption_two2(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_two3(value) {
      console.log(`selected ${value}`);
    },
    filterOption_two3(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_two4(value) {
      console.log(`selected ${value}`);
    },
    filterOption_two4(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three1(value) {
      console.log(`selected ${value}`);
    },
    filterOption_three1(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three2(value) {
      console.log(`selected ${value}`);
    },
    filterOption_three2(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three3(value) {
      console.log(`selected ${value}`);
    },
    filterOption_three3(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three4(value) {
      console.log(`selected ${value}`);
    },
    filterOption_three4(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three5(value) {
      console.log(`selected ${value}`);
    },
    filterOption_three5(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three6(value) {
      console.log(`selected ${value}`);
    },
    filterOption_three6(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three7(value) {
      console.log(`selected ${value}`);
    },
    filterOption_three7(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three8(value) {
      console.log(`selected ${value}`);
    },
    filterOption_three8(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_four1(value) {
      console.log(`selected ${value}`);
    },
    filterOption_four1(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_four2(value) {
      console.log(`selected ${value}`);
    },
    filterOption_four2(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_four3(value) {
      console.log(`selected ${value}`);
    },
    filterOption_four3(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_four4(value) {
      console.log(`selected ${value}`);
    },
    filterOption_four4(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 有效工作时间选择器开关
    onChangeSwitch_one1(checked) {
      console.log(`a-switch to ${checked}`);
    },
    // 正常使用时长
    onChangeSwitch_two1(checked) {
      console.log(`a-switch to ${checked}`);
    },
    // 有效使用时长
    onChangeSwitch_two2(checked) {
      console.log(`a-switch to ${checked}`);
    },
    //  长期在线时长
    onChangeSwitch_two3(checked) {
      console.log(`a-switch to ${checked}`);
    },
    // 在线率低设备
    onChangeSwitch_two4(checked) {
      console.log(`a-switch to ${checked}`);
    },
    //  抓拍时间1
    onChangeSwitch_three1(checked) {
      console.log(`a-switch to ${checked}`);
    },
    //  抓拍时间2
    onChangeSwitch_three2(checked) {
      console.log(`a-switch to ${checked}`);
    }, //  抓拍时间3
    onChangeSwitch_three3(checked) {
      console.log(`a-switch to ${checked}`);
    }, //  抓拍时间4
    onChangeSwitch_three4(checked) {
      console.log(`a-switch to ${checked}`);
    },
    // 考勤打卡 工人
    onChangeSwitch_four1(checked) {
      console.log(`a-switch to ${checked}`);
    },
    // 考勤打卡 项目经理、工程管家、其他岗位
    onChangeSwitch_four2(checked) {
      console.log(`a-switch to ${checked}`);
    },
  },
};
</script>


<style lang="less" scoped>
.colorGray {
  color: gray;
  padding-left: 10px;
}
.right_title {
  font-size: 20px;
  margin-bottom: 10px;
}
.paragraph {
  margin-bottom: 20px;
}
.bgcGray {
  background-color: #d7d7d7;
}
// .ant-switch {
//   // background-color: transparent;
//   // background-color: #ffffff;
//   // border: #0356f9 2px solid;
// }
.ant-switch-checked {
  background-color: #0356f9;
}
// .ant-switch::after {
//   // border: #0356f9 2px solid;
//   // top: 0.5px;
// }
.paragraph_margin {
  // line-height: 30px;
  // height: 40px;
  margin: 5px 0;
}
.padding {
  margin-left: 30px;
  padding-left: 5px;
}
</style>