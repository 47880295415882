<template>
  <div>
    <!-- 一、工地工作时间段设置 -->
    <div class="paragraph">
      <div class="right_title">
        一、 工地工作时间段设置<span class="colorGray"
          >(开启后，使用新的工作时间段，关闭后默认8点-18点)</span
        >
      </div>
      <div>
        有效工作时间段设置<span class="colorGray"
          >(开启后，使用新的工作时间段，关闭后默认8点18点)</span
        >
      </div>
      <!-- 有效工作时间 -->
      <div>
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_one1"
          @change="onChangeSwitch_one1"
        />
        有效工作时间
        <a-select
          show-search
          v-model="placeholder_one_start"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_one_start"
          @change="handleChange_one_start"
          :disabled="disabled_select_one"
        >
          <!--    @focus="handleFocus_one_start"
          @blur="handleBlur_one_start" -->
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >点-
        <a-select
          show-search
          v-model="placeholder_one_end"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_one_end"
          @change="handleChange_one_end"
          :disabled="disabled_select_one"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >点
      </div>
    </div>
    <!-- 二、累计时长设置 -->
    <div class="paragraph">
      <div class="right_title">
        二、 累计时长设置<span class="colorGray">(设置成功后，立即生效)</span>
      </div>
      <!-- 正常使用时长 -->
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_two1"
          @change="onChangeSwitch_two1"
        />
        正常使用时长：全天累计时长大于等于
        <a-select
          show-search
          v-model="placeholder_two1"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_two1"
          @change="handleChange_two1"
          :disabled="disabled_select_two1"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >小时，即为正常有效时长；<span class="colorGray"
          >(关闭后，默认2小时)</span
        >
      </div>
      <!-- 有效使用时长 -->
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_two2"
          @change="onChangeSwitch_two2"
        />
        有效使用时长：有效工作时间内累计大于等于
        <a-select
          show-search
          v-model="placeholder_two2"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_two2"
          @change="handleChange_two2"
          :disabled="disabled_select_two2"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >小时，即为有效使用时长；<span class="colorGray"
          >(关闭后，默认2小时)</span
        >
      </div>
      <!-- 长期在线时长 -->
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_two3"
          @change="onChangeSwitch_two3"
        />
        长期在线时长：全天累计时长大于等于
        <a-select
          show-search
          v-model="placeholder_two3"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_two3"
          @change="handleChange_two3"
          :disabled="disabled_select_two3"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >小时，即为长期在线时长；<span class="colorGray"
          >(关闭后，默认23小时)</span
        >
      </div>
      <!--  在线率低设备 -->
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_two4"
          @change="onChangeSwitch_two4"
        />
        在线率低设备：有效工作时间设备累计在线时长小于
        <a-select
          show-search
          v-model="placeholder_two4"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_two4"
          @change="handleChange_two4"
          :disabled="disabled_select_two4"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >小时，即为在线率低设备；<span class="colorGray"
          >(关闭后，默认2小时)</span
        >
      </div>
    </div>
    <!-- 三、工地形象识别设置 -->
    <div class="paragraph">
      <div class="right_title">
        三、工地形象识别设置<span class="colorGray"
          >(设置成功后，立即生效)</span
        >
      </div>
      <div>
        整洁度优、良、差、摄像头歪曲拍照时间设置，抓拍时间必须间隔30分钟
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_three1"
          @change="onChangeSwitch_three1"
        />
        抓拍时间1
        <a-select
          show-search
          v-model="placeholder_three1"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three1"
          @change="handleChange_three1"
          :disabled="disabled_select_three1"
        >
          <a-select-option v-for="item in 24" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >点
        <a-select
          show-search
          v-model="placeholder_three2"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three2"
          @change="handleChange_three2"
          :disabled="disabled_select_three1"
        >
          <a-select-option v-for="item in 60" :key="item" :value="item - 1">
            {{ item - 1 }}
          </a-select-option> </a-select
        >分
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_three2"
          @change="onChangeSwitch_three2"
        />
        抓拍时间2
        <a-select
          show-search
          v-model="placeholder_three3"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three3"
          @change="handleChange_three3"
          :disabled="disabled_select_three2"
        >
          <a-select-option
            v-for="item in option_three3"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >点
        <a-select
          show-search
          v-model="placeholder_three4"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three4"
          @change="handleChange_three4"
          :disabled="disabled_select_three2"
        >
          <a-select-option
            v-for="item in option_three4"
            :key="item"
            :value="item - 1"
          >
            {{ item - 1 }}
          </a-select-option> </a-select
        >分
        <span v-show="isShowWarn2" style="color: red">
          *抓拍时间必须间隔30分钟</span
        >
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_three3"
          @change="onChangeSwitch_three3"
        />
        抓拍时间3
        <a-select
          show-search
          v-model="placeholder_three5"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three5"
          @change="handleChange_three5"
          :disabled="disabled_select_three3"
        >
          <a-select-option
            v-for="item in option_three5"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >点
        <a-select
          show-search
          v-model="placeholder_three6"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three6"
          @change="handleChange_three6"
          :disabled="disabled_select_three3"
        >
          <a-select-option
            v-for="item in option_three6"
            :key="item"
            :value="item - 1"
          >
            {{ item - 1 }}
          </a-select-option> </a-select
        >分
        <span v-show="isShowWarn3" style="color: red">
          *抓拍时间必须间隔30分钟</span
        >
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_three4"
          @change="onChangeSwitch_three4"
        />

        抓拍时间4
        <a-select
          show-search
          v-model="placeholder_three7"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three7"
          @change="handleChange_three7"
          :disabled="disabled_select_three4"
        >
          <a-select-option
            v-for="item in option_three7"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >点
        <a-select
          show-search
          v-model="placeholder_three8"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_three8"
          @change="handleChange_three8"
          :disabled="disabled_select_three4"
        >
          <a-select-option
            v-for="item in option_three8"
            :key="item"
            :value="item - 1"
          >
            {{ item - 1 }}
          </a-select-option> </a-select
        >分<span v-show="isShowWarn4" style="color: red">
          *抓拍时间必须间隔30分钟</span
        >
      </div>
    </div>
    <!-- 四、考勤打卡管理 -->
    <div class="paragraph">
      <div class="right_title">
        四、考勤打卡管理<span class="colorGray">(设置成功后，立即生效)</span>
      </div>
      <div>
        无感打卡<span class="colorGray"
          >(建议开启，降低打卡播报频率，以免打扰施工者和邻居)</span
        >
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_four1"
          @change="onChangeSwitch_four1"
        />
        同一个设备、同一个人首次打卡后，需要隔
        <a-select
          show-search
          v-model="placeholder_four1"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_four1"
          @change="handleChange_four1"
          :disabled="disabled_select_four1"
        >
          <a-select-option
            v-for="item in option_four1"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
        <a-select
          show-search
          v-model="placeholder_four2"
          option-filter-prop="children"
          style="width: 75px"
          :filter-option="filterOption_four2"
          @change="handleChange_four2"
          :disabled="disabled_select_four1"
        >
          <a-select-option
            v-for="(item, index) in selectHorS"
            :key="index"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >再次打卡才播报打卡结果。*注，不影响正常打卡，仅仅是不在语音播报
        <div class="bgcGray paragraph_margin padding">仅对工人打卡生效</div>
      </div>
      <div class="paragraph_margin">
        <a-switch
          size="small"
          :disabled="disabled_switch"
          :checked="defaultChecked_four2"
          @change="onChangeSwitch_four2"
        />
        同一个设备、同一个人首次打卡后，需要隔
        <a-select
          show-search
          v-model="placeholder_four3"
          option-filter-prop="children"
          style="width: 6%"
          :filter-option="filterOption_four3"
          @change="handleChange_four3"
          :disabled="disabled_select_four2"
        >
          <a-select-option
            v-for="item in option_four2"
            :key="item"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
        <a-select
          show-search
          v-model="placeholder_four4"
          option-filter-prop="children"
          style="width: 75px"
          :filter-option="filterOption_four4"
          @change="handleChange_four4"
          :disabled="disabled_select_four2"
        >
          <a-select-option
            v-for="(item, index) in selectHorS"
            :key="index"
            :value="item"
          >
            {{ item }}
          </a-select-option> </a-select
        >再次打卡才播报打卡结果。*注，不影响正常打卡，仅仅是不在语音播报
        <div class="bgcGray paragraph_margin padding">
          仅对项目经理、工程管家、其他岗位人员打卡生效
        </div>
      </div>
    </div>
    <div class="btns">
      <a-button @click="cancelBtn"> 取消编辑 </a-button>
      <a-button type="primary" @click="saveBtn">保存设置</a-button>
      <a-button @click="resetBtn"> 重置 </a-button>
    </div>
  </div>
</template>

<script>
import { GetselectByBcId, setSelfupdate } from "../../api/role";
export default {
  name: "ViewSet",
  props: {
    bcIds: {
      // type: String,
      default: [],
    },
  },
  data() {
    return {
      disabled: false,
      tSetSelf: {
        // 有效时间
        ssEffectiveuseSpotBegin: 8 * 3600,
        ssEffectiveuseSpotEnd: 18 * 3600,
        ssEffectiveuseSpotOnoff: 1,
        // 正常使用时长
        ssNormaluse: 2 * 3600,
        ssNormaluseOnoff: 1,
        // 有效使用时长
        ssEffectiveuseSum: 2 * 3600,
        ssEffectiveuseSumOnoff: 1,
        // 长期在线时长
        ssLonguseOnoff: 1,
        ssLonguse: 23 * 3600,
        //在线率低设备
        ssLowuseOnoff: 2,
        ssLowuse: 2 * 3600,
        // 抓拍时间1
        ssCleanTime1Onoff: 1,
        ssCleanTime1: 7 * 3600 + 50 * 60,
        // 抓拍时间2
        ssCleanTime2Onoff: 1,
        ssCleanTime2: 15 * 3600,
        // 抓拍时间3
        ssCleanTime3Onoff: 2,
        ssCleanTime3: 0,
        // 抓拍时间4
        ssCleanTime4Onoff: 2,
        ssCleanTime4: 0,
        // 仅对工人打卡生效开关
        ssAtimeWorkerOnoff: 1,
        ssAtimeWorker: 4 * 3600,
        // 除工人外的   打卡
        ssAtimeWorkerboss: 5 * 60,
        ssAtimeWorkerbossOnoff: 1,
      },
      placeholder_one_start: "8",
      placeholder_one_end: "18",
      placeholder_two1: "2",
      placeholder_two2: "2",
      placeholder_two3: "23",
      placeholder_two4: "2",
      placeholder_three1: "7",
      placeholder_three2: "50",
      placeholder_three3: "15",
      placeholder_three4: "0",
      placeholder_three5: "0",
      placeholder_three6: "",
      placeholder_three7: "",
      placeholder_three8: "",
      placeholder_four1: "4",
      placeholder_four3: "5",
      defaultChecked_one1: true, //有效工作时间开关
      disabled_select_one: false, //有效时长是否可选

      defaultChecked_two1: true, //正常使用时长
      disabled_select_two1: false, //正常使用时长是否可选

      defaultChecked_two2: true, //有效使用时长
      disabled_select_two2: false,

      defaultChecked_two3: true, //长期在线时长
      disabled_select_two3: false,

      defaultChecked_two4: false, //在线率低
      disabled_select_two4: true,

      defaultChecked_three1: true, //抓拍时间1
      disabled_select_three1: false,

      defaultChecked_three2: true, //抓拍时间2
      disabled_select_three2: false,
      isShowWarn2: false, //是否显示抓拍时间必须间隔30分钟字样

      defaultChecked_three3: false, //抓拍时间3
      disabled_select_three3: true,
      isShowWarn3: false,

      defaultChecked_three4: true, //抓拍时间4
      disabled_select_three4: false,
      isShowWarn4: false,

      defaultChecked_four1: true, //工人
      disabled_select_four1: false,

      defaultChecked_four2: false, //其他
      disabled_select_four2: true,

      placeholder_four2: "小时",
      placeholder_four4: "分钟",
      selectHorS: ["小时", "分钟"],
      option_three3: [],
      option_three4: [],
      option_three5: [],
      option_three6: [],
      option_three7: [],
      option_three8: [],
      option_four1: 24,
      option_four2: 24,
      disabled_switch: false, //开关是否禁用
      today: new Date(new Date().toLocaleDateString()).getTime() / 1000,
      time2_lowest_M: 0,
      time3_lowest_M: 0,
      time4_lowest_M: 0,
    };
  },
  created() {
    // 今天0点时间戳
    // let today = new Date(new Date().toLocaleDateString()).getTime() / 1000;
    // 处理抓拍时间2的小时分钟 可选区间
    // 抓拍时间1的时间戳
    let time1 =
      this.today +
      this.placeholder_three1 * 3600 +
      this.placeholder_three2 * 60;
    // 抓拍时间必须间隔30分钟   30*60=1800
    // 抓拍时间1 + 30分钟 的时间
    let time2_lowest = this.$Format(time1 + 1800).split(" ")[1];
    let time2_lowest_H = time2_lowest.split(":")[0];
    let time2_lowest_M = time2_lowest.split(":")[1] * 1 + 1;
    // 抓拍时间2小时数 可选择数组
    for (let item = 0; item < 24; item++) {
      if (item >= time2_lowest_H) {
        this.option_three3.push(item);
      }
    }
    // 抓拍时间2分钟数 可选择数组
    for (let item = 0; item < 61; item++) {
      if (item >= time2_lowest_M) {
        this.option_three4.push(item);
      }
    }
    // 处理抓拍时间3的小时分钟 可选区间
    // 抓拍时间2的时间戳
    let time2 =
      this.today +
      this.placeholder_three3 * 3600 +
      this.placeholder_three4 * 60;
    let time3_lowest = this.$Format(time2 + 1800).split(" ")[1];
    let time3_lowest_H = time3_lowest.split(":")[0];
    let time3_lowest_M = time3_lowest.split(":")[1] * 1 + 1;
    for (let item = 0; item < 24; item++) {
      if (item >= time3_lowest_H) {
        this.option_three5.push(item);
      }
    }
    for (let item = 0; item < 61; item++) {
      if (item >= time3_lowest_M) {
        this.option_three6.push(item);
      }
    }
    // 处理抓拍时间4的小时分钟 可选区间
    let time3 =
      this.today +
      this.placeholder_three5 * 3600 +
      this.placeholder_three6 * 60;
    let time4_lowest = this.$Format(time3 + 1800).split(" ")[1];
    let time4_lowest_H = time4_lowest.split(":")[0];
    let time4_lowest_M = time4_lowest.split(":")[1] * 1 + 1;
    for (let item = 0; item < 24; item++) {
      if (item >= time4_lowest_H) {
        this.option_three7.push(item);
      }
    }
    for (let item = 0; item < 61; item++) {
      if (item >= time4_lowest_M) {
        this.option_three8.push(item);
      }
    }
  },
  mounted() {
    this.GetselectByBcId(this.bcIds[0]);
  },
  watch: {
    placeholder_three1(newValue) {
      this.option_three3 = [];
      this.option_three4 = [];
      let time1 = this.today + newValue * 3600 + this.placeholder_three2 * 60;
      // 抓拍时间必须间隔30分钟   30*60=1800
      // 抓拍时间1 + 30分钟 的时间
      let time2_lowest = this.$Format(time1 + 1800).split(" ")[1];
      let time2_lowest_H = time2_lowest.split(":")[0];
      let time2_lowest_M = time2_lowest.split(":")[1] * 1 + 1;
      // 抓拍时间2小时数 可选择数组
      for (let item = 0; item < 24; item++) {
        if (item >= time2_lowest_H) {
          this.option_three3.push(item);
        }
      }
      // 抓拍时间2分钟数 可选择数组
      for (let item = 0; item < 61; item++) {
        if (item >= time2_lowest_M) {
          this.option_three4.push(item);
        }
      }
      // 判断下一个时间是否大于这个时间加30分钟
      let nextTime =
        this.today +
        this.placeholder_three3 * 3600 +
        this.placeholder_three4 * 60;
      if (nextTime < time1 + 1800 && !this.disabled_select_two2) {
        this.isShowWarn2 = true;
      } else {
        this.isShowWarn2 = false;
      }
    },
    placeholder_three2(newValue) {
      this.option_three3 = [];
      this.option_three4 = [];
      let time1 = this.today + this.placeholder_three1 * 3600 + newValue * 60;
      // 抓拍时间必须间隔30分钟   30*60=1800
      // 抓拍时间1 + 30分钟 的时间
      let time2_lowest = this.$Format(time1 + 1800).split(" ")[1];
      let time2_lowest_H = time2_lowest.split(":")[0];
      this.time2_lowest_M = time2_lowest.split(":")[1] * 1 + 1;
      // 抓拍时间2小时数 可选择数组
      for (let item = 0; item < 24; item++) {
        if (item >= time2_lowest_H) {
          this.option_three3.push(item);
        }
      }
      // 抓拍时间2分钟数 可选择数组
      for (let item = 0; item < 61; item++) {
        if (item >= this.time2_lowest_M) {
          this.option_three4.push(item);
        }
      }

      // 判断下一个时间是否大于这个时间加30分钟
      let nextTime =
        this.today +
        this.placeholder_three3 * 3600 +
        this.placeholder_three4 * 60;
      if (nextTime < time1 + 1800) {
        console.log("抓拍时间必须间隔30分钟");
        if (!this.disabled_select_two2) {
          this.isShowWarn2 = true;
        }
      } else {
        this.isShowWarn2 = false;
      }
    },
    // 监听抓拍时间2的小时数
    placeholder_three3(newValue) {
      this.option_three5 = [];
      this.option_three6 = [];
      this.option_three4 = [];
      // 抓拍时间2的时间戳
      let time2 = this.today + newValue * 3600 + this.placeholder_three4 * 60;
      let time3_lowest = this.$Format(time2 + 1800).split(" ")[1];
      let time3_lowest_H = time3_lowest.split(":")[0];
      let time3_lowest_M = time3_lowest.split(":")[1] * 1 + 1;
      for (let item = 0; item < 24; item++) {
        if (item >= time3_lowest_H) {
          this.option_three5.push(item);
        }
      }
      for (let item = 0; item < 61; item++) {
        if (item >= time3_lowest_M) {
          this.option_three6.push(item);
        }
      }
      let time1 =
        this.placeholder_three1 * 3600 + this.placeholder_three2 * 60 + 30 * 60;
      // // 监听抓拍时间2的小时数
      if (newValue * 3600 > time1) {
        this.option_three4 = 60;
      } else {
        for (let item = 0; item < 61; item++) {
          if (item >= this.time2_lowest_M) {
            this.option_three4.push(item);
          }
        }
      }
      // 抓拍时间1的时间戳
      let time1_SJC =
        this.today +
        this.placeholder_three1 * 3600 +
        this.placeholder_three2 * 60;
      if (time2 < time1_SJC + 30 * 60) {
        console.log("抓拍时间必须间隔30分钟");
        this.isShowWarn2 = true;
      } else {
        this.isShowWarn2 = false;
      }
      //  // 判断下一个时间是否大于这个时间加30分钟
      let nextTime =
        this.today +
        this.placeholder_three5 * 3600 +
        this.placeholder_three6 * 60;
      if (nextTime < time2 + 1800) {
        console.log("抓拍时间必须间隔30分钟");
        if (this.disabled_select_two3 == false) {
          this.isShowWarn3 = true;
        }
      } else {
        this.isShowWarn3 = false;
      }
    },
    placeholder_three4(newValue) {
      this.option_three5 = [];
      this.option_three6 = [];
      // 抓拍时间2的时间戳
      let time2 = this.today + this.placeholder_three3 * 3600 + newValue * 60;
      let time3_lowest = this.$Format(time2 + 1800).split(" ")[1];
      let time3_lowest_H = time3_lowest.split(":")[0];
      this.time3_lowest_M = time3_lowest.split(":")[1] * 1 + 1;
      for (let item = 0; item < 24; item++) {
        if (item >= time3_lowest_H) {
          this.option_three5.push(item);
        }
      }
      for (let item = 0; item < 61; item++) {
        if (item >= this.time3_lowest_M) {
          this.option_three6.push(item);
        }
      }
      // 抓拍时间1的时间戳
      let time1_SJC =
        this.today +
        this.placeholder_three1 * 3600 +
        this.placeholder_three2 * 60;
      if (time2 < time1_SJC + 30 * 60) {
        console.log("抓拍时间必须间隔30分钟");
        if (this.disabled_select_three2 == false) {
          this.isShowWarn2 = true;
        }
      } else {
        this.isShowWarn2 = false;
      }

      //  // 判断下一个时间是否大于这个时间加30分钟
      let nextTime =
        this.today +
        this.placeholder_three5 * 3600 +
        this.placeholder_three6 * 60;
      if (nextTime < time2 + 1800) {
        console.log("抓拍时间必须间隔30分钟");
        if (this.disabled_select_two3 == false) {
          this.isShowWarn3 = true;
        }
      } else {
        this.isShowWarn3 = false;
      }
    },
    // 监听抓拍时间3的小时数
    placeholder_three5(newValue) {
      this.option_three7 = [];
      this.option_three8 = [];
      this.option_three6 = [];
      let time3 = this.today + newValue * 3600 + this.placeholder_three6 * 60;
      let time4_lowest = this.$Format(time3 + 1800).split(" ")[1];
      let time4_lowest_H = time4_lowest.split(":")[0];
      let time4_lowest_M = time4_lowest.split(":")[1] * 1 + 1;
      for (let item = 0; item < 24; item++) {
        if (item >= time4_lowest_H) {
          this.option_three7.push(item);
        }
      }
      for (let item = 0; item < 61; item++) {
        if (item >= time4_lowest_M) {
          this.option_three8.push(item);
        }
      }
      let time1 =
        this.placeholder_three3 * 3600 + this.placeholder_three4 * 60 + 30 * 60;
      // // 监听抓拍时间3的小时数
      if (newValue * 3600 > time1) {
        this.option_three6 = 60;
      } else {
        for (let item = 0; item < 61; item++) {
          if (item >= this.time3_lowest_M) {
            this.option_three6.push(item);
          }
        }
      }
      // 抓拍时间2的时间戳
      let time1_SJC =
        this.today +
        this.placeholder_three3 * 3600 +
        this.placeholder_three4 * 60;
      if (time3 < time1_SJC + 30 * 60) {
        console.log("抓拍时间必须间隔30分钟");
        if (this.disabled_select_three3 == false) {
          this.isShowWarn3 = true;
        } else {
          this.isShowWarn3 = false;
        }
      } else {
        this.isShowWarn3 = false;
      }

      //  // 判断下一个时间是否大于这个时间加30分钟
      let nextTime =
        this.today +
        this.placeholder_three7 * 3600 +
        this.placeholder_three8 * 60;
      if (nextTime < time3 + 1800) {
        console.log("抓拍时间必须间隔30分钟");
        if (this.disabled_select_three4 == false) {
          this.isShowWarn4 = true;
        } else {
          this.isShowWarn4 = false;
        }
      } else {
        this.isShowWarn4 = false;
      }
    },
    placeholder_three6(newValue) {
      this.option_three7 = [];
      this.option_three8 = [];
      let time3 = this.today + this.placeholder_three5 * 3600 + newValue * 60;
      let time4_lowest = this.$Format(time3 + 1800).split(" ")[1];
      let time4_lowest_H = time4_lowest.split(":")[0];
      this.time4_lowest_M = time4_lowest.split(":")[1] * 1 + 1;
      for (let item = 0; item < 24; item++) {
        if (item >= time4_lowest_H) {
          this.option_three7.push(item);
        }
      }
      for (let item = 0; item < 61; item++) {
        if (item >= this.time4_lowest_M) {
          this.option_three8.push(item);
        }
      }
      // 抓拍时间2的时间戳
      let time1_SJC =
        this.today +
        this.placeholder_three3 * 3600 +
        this.placeholder_three4 * 60;
      if (time3 < time1_SJC + 30 * 60) {
        console.log("抓拍时间必须间隔30分钟");

        if (this.disabled_select_three3 == false) {
          this.isShowWarn3 = true;
        }
      } else {
        this.isShowWarn3 = false;
      }
      //  // 判断下一个时间是否大于这个时间加30分钟
      let nextTime =
        this.today +
        this.placeholder_three7 * 3600 +
        this.placeholder_three8 * 60;
      if (nextTime < time3 + 1800) {
        console.log("抓拍时间必须间隔30分钟6666");
        if (this.disabled_select_three4 == false) {
          this.isShowWarn4 = true;
        }
      } else {
        this.isShowWarn4 = false;
      }
    },
    placeholder_three7(newValue) {
      this.option_three8 = [];
      let time1 =
        this.placeholder_three5 * 3600 + this.placeholder_three6 * 60 + 30 * 60;
      if (newValue * 3600 > time1) {
        this.option_three8 = 60;
      } else {
        for (let item = 0; item < 61; item++) {
          if (item >= this.time3_lowest_M) {
            this.option_three8.push(item);
          }
        }
      }
      // 抓拍时间3的时间戳
      let time1_SJC =
        this.today +
        this.placeholder_three5 * 3600 +
        this.placeholder_three6 * 60;
      // 抓拍时间4的时间戳
      let time4 = this.today + newValue * 3600 + this.placeholder_three8 * 60;
      if (time4 < time1_SJC + 30 * 60) {
        console.log("抓拍时间必须间隔30分钟");
        if (this.disabled_select_three4 == false) {
          this.isShowWarn4 = true;
        }
      } else {
        this.isShowWarn4 = false;
      }
    },
    placeholder_three8(newVal) {
      // 抓拍时间3的时间戳
      let time1_SJC =
        this.today +
        this.placeholder_three5 * 3600 +
        this.placeholder_three6 * 60;
      // 抓拍时间4的时间戳
      let time4 = this.today + this.placeholder_three7 * 3600 + newVal * 60;
      if (time4 < time1_SJC + 30 * 60) {
        console.log("抓拍时间必须间隔30分钟");
        if (this.disabled_select_three4 == false) {
          this.isShowWarn4 = true;
        }
      } else {
        this.isShowWarn4 = false;
      }
    },
    bcIds(newValue) {
      if (newValue.length == 1) {
        this.GetselectByBcId(newValue[0]);
      }
    },
    // tSetSelf(newValue) {
    //   console.log(newValue);
    // },
    placeholder_four2(newVal) {
      if (newVal == "小时") {
        this.option_four1 = 24;
      } else {
        this.option_four1 = 60;
      }
    },
    placeholder_four4(newVal) {
      if (newVal == "小时") {
        this.option_four2 = 24;
      } else {
        this.option_four2 = 60;
      }
    },
    defaultChecked_one1(newVal) {
      this.disabled_select_one = !newVal;
    },
    defaultChecked_two1(newVal) {
      this.disabled_select_two1 = !newVal;
    },
    defaultChecked_two2(newVal) {
      this.disabled_select_two2 = !newVal;
    },
    defaultChecked_two3(newVal) {
      this.disabled_select_two3 = !newVal;
    },
    defaultChecked_two4(newVal) {
      this.disabled_select_two4 = !newVal;
    },
    defaultChecked_three1(newVal) {
      this.disabled_select_three1 = !newVal;
    },
    defaultChecked_three2(newVal) {
      this.disabled_select_three2 = !newVal;
      // 如果关闭可选，就不显示文字
      let time1 =
        this.today +
        this.placeholder_three1 * 3600 +
        this.placeholder_three2 * 60;
      let time2 =
        this.today +
        this.placeholder_three3 * 3600 +
        this.placeholder_three4 * 60;
      if (newVal == true && time2 < time1 + 1800) {
        this.isShowWarn2 = true;
      } else {
        this.isShowWarn2 = false;
      }
    },
    defaultChecked_three3(newVal) {
      this.disabled_select_three3 = !newVal;
      // 如果关闭可选，就不显示文字
      let time1 =
        this.today +
        this.placeholder_three3 * 3600 +
        this.placeholder_three4 * 60;
      let time2 =
        this.today +
        this.placeholder_three5 * 3600 +
        this.placeholder_three6 * 60;
      if (newVal == true && time2 < time1 + 1800) {
        this.isShowWarn3 = true;
      } else {
        this.isShowWarn3 = false;
      }
    },
    defaultChecked_three4(newVal) {
      this.disabled_select_three4 = !newVal;
      //        // 如果关闭可选，就不显示文字
      let time1 =
        this.today +
        this.placeholder_three5 * 3600 +
        this.placeholder_three6 * 60;
      let time2 =
        this.today +
        this.placeholder_three7 * 3600 +
        this.placeholder_three8 * 60;
      if (newVal == true && time2 < time1 + 1800) {
        this.isShowWarn4 = true;
      } else {
        this.isShowWarn4 = false;
      }
    },
    defaultChecked_four1(newVal) {
      this.disabled_select_four1 = !newVal;
    },
    defaultChecked_four2(newVal) {
      this.disabled_select_four2 = !newVal;
    },

    deep: true, // 深度监听
    immediate: true, // 第一次改变就执行
  },
  methods: {
    // 根据id获取 查看信息
    GetselectByBcId(bc_id) {
      let param = { bcId: bc_id };
      let orderParam = ["bcId"];
      const params = {
        ...param,
        verify: this.$verify(param, orderParam),
      };
      GetselectByBcId(params).then((res) => {
        // console.log(res);
        this.tSetSelf = res.data;
        let data = res.data;
        // 有效工作时间
        this.defaultChecked_one1 =
          data.ssEffectiveuseSpotOnoff == 1 ? true : false;
        this.placeholder_one_start = data.ssEffectiveuseSpotBegin / 3600;
        this.placeholder_one_end = data.ssEffectiveuseSpotEnd / 3600;
        // 正常使用时长
        this.defaultChecked_two1 = data.ssNormaluseOnoff == 1 ? true : false;
        this.placeholder_two1 = data.ssNormaluse / 3600;
        // 有效使用时长
        this.defaultChecked_two2 =
          data.ssEffectiveuseSumOnoff == 1 ? true : false;
        this.placeholder_two2 = data.ssEffectiveuseSum / 3600;
        // 长期在线时长
        this.defaultChecked_two3 = data.ssLonguseOnoff == 1 ? true : false;
        this.placeholder_two3 = data.ssLonguse / 3600;
        //在线率低设备
        this.defaultChecked_two4 = data.ssLowuseOnoff == 1 ? true : false;
        this.placeholder_two4 = data.ssLowuse / 3600;
        // 抓拍时间1
        this.defaultChecked_three1 = data.ssCleanTime1Onoff == 1 ? true : false;
        this.placeholder_three1 = parseInt(data.ssCleanTime1 / 3600);
        this.placeholder_three2 = parseInt(
          ((data.ssCleanTime1 / 3600) % 1) * 60
        );
        // 抓拍时间2
        this.defaultChecked_three2 = data.ssCleanTime2Onoff == 1 ? true : false;
        this.placeholder_three3 = parseInt(data.ssCleanTime2 / 3600);
        this.placeholder_three4 = parseInt(
          ((data.ssCleanTime2 / 3600) % 1) * 60
        );
        // 抓拍时间3
        this.defaultChecked_three3 = data.ssCleanTime3Onoff == 1 ? true : false;
        this.placeholder_three5 = parseInt(data.ssCleanTime3 / 3600);
        this.placeholder_three6 = parseInt(
          ((data.ssCleanTime3 / 3600) % 1) * 60
        );
        // 抓拍时间4
        this.defaultChecked_three4 = data.ssCleanTime4Onoff == 1 ? true : false;
        this.placeholder_three7 = parseInt(data.ssCleanTime4 / 3600);
        this.placeholder_three8 = parseInt(
          ((data.ssCleanTime4 / 3600) % 1) * 60
        );
        // 仅对工人打卡生效开关
        this.defaultChecked_four1 = data.ssAtimeWorkerOnoff == 1 ? true : false;
        if (data.ssAtimeWorker / 3600 >= 1) {
          this.placeholder_four1 = parseInt(data.ssAtimeWorker / 3600);
          this.placeholder_four2 = "小时";
          this.option_four1 = 24;
        } else {
          this.placeholder_four1 = parseInt(data.ssAtimeWorker / 60);
          this.placeholder_four2 = "分钟";
          this.option_four1 = 60;
        }

        // 除工人外的   打卡
        this.defaultChecked_four2 =
          data.ssAtimeWorkerbossOnoff == 1 ? true : false;
        if (data.ssAtimeWorkerboss / 3600 >= 1) {
          this.placeholder_four3 = parseInt(data.ssAtimeWorkerboss / 3600);
          this.placeholder_four4 = "小时";
          this.option_four2 = 24;
        } else {
          this.placeholder_four3 = parseInt(data.ssAtimeWorkerboss / 60);
          this.placeholder_four4 = "分钟";
          this.option_four2 = 60;
        }
      });
    },
    // setSelfupdate   编辑更新个性化设置
    SetSelfupdate(bcId, data) {
      let bcIds = bcId.toString();
      let orderParam = ["bcIds"];
      let param = {
        bcIds: bcId.toString(),
      };
      const params = {
        bcIds,
        verify: this.$verify(param, orderParam),
      };
      setSelfupdate(params, data).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.data);
        } else {
          this.$message.warning(res.data);
        }
      });
    },
    // 有效工作时间
    handleChange_one_start(value) {
      let time = value * 3600;
      this.tSetSelf.ssEffectiveuseSpotBegin = time;
      console.log(this.tSetSelf);
    },
    filterOption_one_start(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_one_end(value) {
      let time = value * 3600;
      this.tSetSelf.ssEffectiveuseSpotEnd = time;
    },
    filterOption_one_end(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 正常使用时长
    handleChange_two1(value) {
      let time = value * 3600;
      this.tSetSelf.ssNormaluse = time;
    },
    filterOption_two1(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //  有效使用时长
    handleChange_two2(value) {
      let time = value * 3600;
      this.tSetSelf.ssEffectiveuseSum = time;
    },
    filterOption_two2(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 长期在线时长
    handleChange_two3(value) {
      let time = value * 3600;
      this.tSetSelf.ssLonguse = time;
    },
    filterOption_two3(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 在线率低设备
    handleChange_two4(value) {
      let time = value * 3600;
      this.tSetSelf.ssLowuse = time;
    },
    filterOption_two4(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //  抓拍时间1
    handleChange_three1(value) {
      this.placeholder_three1 = value;
      this.tSetSelf.ssCleanTime1 = value * 3600 + this.placeholder_three2 * 60;
    },
    filterOption_three1(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three2(value) {
      this.placeholder_three2 = value;
      this.tSetSelf.ssCleanTime1 = this.placeholder_three1 * 3600 + value * 60;
    },
    filterOption_three2(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //  抓拍时间2
    handleChange_three3(value) {
      this.placeholder_three3 = value;
      this.tSetSelf.ssCleanTime2 = value * 3600 + this.placeholder_three4 * 60;
    },
    filterOption_three3(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three4(value) {
      console.log(`selected ${value}`);
      this.placeholder_three4 = value;
      this.tSetSelf.ssCleanTime2 = this.placeholder_three3 * 3600 + value * 60;
    },
    filterOption_three4(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //  抓拍时间3
    handleChange_three5(value) {
      this.placeholder_three5 = value;
      this.tSetSelf.ssCleanTime3 = value * 3600 + this.placeholder_three6 * 60;
    },
    filterOption_three5(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three6(value) {
      this.placeholder_three6 = value;
      this.tSetSelf.ssCleanTime3 = this.placeholder_three5 * 3600 + value * 60;
    },
    filterOption_three6(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //  抓拍时间4
    handleChange_three7(value) {
      this.placeholder_three7 = value;
      this.tSetSelf.ssCleanTime4 = value * 3600 + this.placeholder_three8 * 60;
    },
    filterOption_three7(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_three8(value) {
      this.placeholder_three8 = value;
      this.tSetSelf.ssCleanTime4 = this.placeholder_three7 * 3600 + value * 60;
    },
    filterOption_three8(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 仅对工人打卡生效  无感打卡
    handleChange_four1(value) {
      console.log(`selected ${value}`);
      this.placeholder_four1 = value;
      if (this.placeholder_four2 == "小时") {
        this.tSetSelf.ssAtimeWorker = value * 3600;
      } else {
        this.tSetSelf.ssAtimeWorker = value * 60;
      }
    },
    filterOption_four1(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_four2(value) {
      console.log(`selected ${value}`);
      if (value == "小时") {
        this.tSetSelf.ssAtimeWorker = this.placeholder_four1 * 3600;
      } else {
        this.tSetSelf.ssAtimeWorker = this.placeholder_four1 * 60;
      }
    },
    filterOption_four2(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 除工人外打卡生效  无感打卡
    handleChange_four3(value) {
      if (this.placeholder_four4 == "小时") {
        this.tSetSelf.ssAtimeWorkerboss = value * 3600;
      } else {
        this.tSetSelf.ssAtimeWorkerboss = value * 60;
      }
    },
    filterOption_four3(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange_four4(value) {
      if (value == "小时") {
        this.tSetSelf.ssAtimeWorkerboss = this.placeholder_four3 * 3600;
      } else {
        this.tSetSelf.ssAtimeWorkerboss = this.placeholder_four3 * 60;
      }
    },
    filterOption_four4(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 有效工作时间选择器开关
    onChangeSwitch_one1(checked) {
      this.defaultChecked_one1 = checked;
      this.tSetSelf.ssEffectiveuseSpotOnoff = checked == true ? 1 : 2;
      console.log(this.defaultChecked_one1);
    },
    // 正常使用时长
    onChangeSwitch_two1(checked) {
      this.defaultChecked_two1 = checked;
      this.tSetSelf.ssNormaluseOnoff = checked == true ? 1 : 2;
    },
    // 有效使用时长
    onChangeSwitch_two2(checked) {
      this.defaultChecked_two2 = checked;
      this.tSetSelf.ssEffectiveuseSumOnoff = checked == true ? 1 : 2;
    },
    //  长期在线时长
    onChangeSwitch_two3(checked) {
      this.defaultChecked_two3 = checked;
      this.tSetSelf.ssLonguseOnoff = checked == true ? 1 : 2;
    },
    // 在线率低设备
    onChangeSwitch_two4(checked) {
      this.defaultChecked_two4 = checked;
      this.tSetSelf.ssLowuseOnoff = checked == true ? 1 : 2;
    },
    //  抓拍时间1
    onChangeSwitch_three1(checked) {
      this.defaultChecked_three1 = checked;
      this.tSetSelf.ssCleanTime1Onoff = checked == true ? 1 : 2;
    },
    //  抓拍时间2
    onChangeSwitch_three2(checked) {
      this.defaultChecked_three2 = checked;
      this.tSetSelf.ssCleanTime2Onoff = checked == true ? 1 : 2;
    }, //  抓拍时间3
    onChangeSwitch_three3(checked) {
      this.defaultChecked_three3 = checked;
      this.tSetSelf.ssCleanTime3Onoff = checked == true ? 1 : 2;
    }, //  抓拍时间4
    onChangeSwitch_three4(checked) {
      this.defaultChecked_three4 = checked;
      this.tSetSelf.ssCleanTime4Onoff = checked == true ? 1 : 2;
    },
    // 考勤打卡 工人
    onChangeSwitch_four1(checked) {
      this.defaultChecked_four1 = checked;
      this.tSetSelf.ssAtimeWorkerOnoff = checked == true ? 1 : 2;
    },
    // 考勤打卡 项目经理、工程管家、其他岗位
    onChangeSwitch_four2(checked) {
      this.defaultChecked_four2 = checked;
      this.tSetSelf.ssAtimeWorkerbossOnoff = checked == true ? 1 : 2;
    },
    // 取消编辑   返回查看页面，关闭当前编辑页面；
    cancelBtn() {
      // 调用祖父组件的方法，关闭编辑页面
      this.editclick();
    },
    // 保存设置   保存这次的设置数据
    saveBtn() {
      if (!this.isShowWarn2 && !this.isShowWarn3 && !this.isShowWarn4) {
        console.log("可以提交");
        this.SetSelfupdate(this.bcIds, this.tSetSelf);
      } else {
        this.$message.warning("时间设置错误");
      }

      // this.SetSelfupdate(this.bcIds, this.tSetSelf);
    },
    // 重置   返回最初的默认设置
    resetBtn() {
      this.placeholder_one_start = "8";
      this.placeholder_one_end = "18";
      this.placeholder_two1 = "2";
      this.placeholder_two2 = "2";
      this.placeholder_two3 = "23";
      this.placeholder_two4 = "2";
      this.placeholder_three1 = "7";
      this.placeholder_three2 = "50";
      this.placeholder_three3 = "15";
      this.placeholder_three4 = "0";
      this.placeholder_three5 = "";
      this.placeholder_three6 = "";
      this.placeholder_three7 = "";
      this.placeholder_three8 = "";
      this.placeholder_four1 = "4";
      this.placeholder_four2 = "小时";
      this.placeholder_four3 = "5";
      this.placeholder_four4 = "分钟";
      this.selectHorS = ["小时", "分钟"];
      this.tSetSelf = {
        // 有效时间
        ssEffectiveuseSpotBegin: 8 * 3600,
        ssEffectiveuseSpotEnd: 18 * 3600,
        ssEffectiveuseSpotOnoff: 1,
        // 正常使用时长
        ssNormaluse: 2 * 3600,
        ssNormaluseOnoff: 1,
        // 有效使用时长
        ssEffectiveuseSum: 2 * 3600,
        ssEffectiveuseSumOnoff: 1,
        // 长期在线时长
        ssLonguseOnoff: 1,
        ssLonguse: 23 * 3600,
        //在线率低设备
        ssLowuseOnoff: 2,
        ssLowuse: 2 * 3600,
        // 抓拍时间1
        ssCleanTime1Onoff: 1,
        ssCleanTime1: 7 * 3600 + 50 * 60,
        // 抓拍时间2
        ssCleanTime2Onoff: 1,
        ssCleanTime2: 15 * 3600,
        // 抓拍时间3
        ssCleanTime3Onoff: 2,
        ssCleanTime3: 0,
        // 抓拍时间4
        ssCleanTime4Onoff: 2,
        ssCleanTime4: 0,
        // 仅对工人打卡生效开关
        ssAtimeWorkerOnoff: 1,
        ssAtimeWorker: 4 * 3600,
        // 除工人外的   打卡
        ssAtimeWorkerboss: 1 * 60,
        ssAtimeWorkerbossOnoff: 1,
      };
      // this.SetSelfupdate(this.bcIds, this.tSetSelf);
    },
  },
  inject: ["editclick"],
};
</script>


<style lang="less" scoped>
.colorGray {
  color: gray;
  padding-left: 10px;
}
.right_title {
  font-size: 20px;
  margin-bottom: 10px;
}
.paragraph {
  margin-bottom: 20px;
}
.bgcGray {
  background-color: #d7d7d7;
}
// .ant-switch {
//   // background-color: transparent;
//   // background-color: #ffffff;
//   // border: #0356f9 2px solid;
// }
.ant-switch-checked {
  background-color: #0356f9;
}
// .ant-switch::after {
//   // border: #0356f9 2px solid;
//   // top: 0.5px;
// }
.paragraph_margin {
  // line-height: 30px;
  // height: 40px;
  margin: 5px 0;
}
.padding {
  margin-left: 30px;
  padding-left: 5px;
}
.btns {
  text-align: right;
  .ant-btn {
    margin-right: 10px !important;
  }
}
</style>