<template>
  <div>
    修改记录
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      @change="handleTableChange"
      rowKey="ulTime"
      bordered
      size="small"
      :scroll="{ x: 700 }"
    >
      <span slot="ulTime" slot-scope="text">
        {{ $Format(text) }}
      </span>
      <span slot="ulContent" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span style="cursor: pointer">{{ text }}</span>
        </a-tooltip>
      </span>
    </a-table>
    <a-pagination
      size="middle"
      v-model="current"
      :pageSize="pageSize"
      @change="pageNumberChange"
      @showSizeChange="sizeChange"
      :pageSizeOptions="sizeList"
      :total="total"
      showSizeChanger
      style="margin: 20px 0; text-align: right"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "修改人",
    dataIndex: "ulUName",
    align: "center",
    width: "100px",
  },
  {
    title: "修改时间",
    dataIndex: "ulTime",
    align: "center",
    scopedSlots: { customRender: "ulTime" },
    width: "150px",
  },
  //   {
  //     title: "组织名称",
  //     // dataIndex: "w_post",
  //     align: "center",
  //     width: "200px",
  //   },
  {
    title: "描述",
    dataIndex: "ulContent",
    scopedSlots: { customRender: "ulContent" },
    align: "center",
    width: "450px",
    ellipsis: true,
  },
];
import { GetuserLog } from "../../api/role";
import { STable } from "@/components";
export default {
  name: "AmendantRecord",
  components: {
    STable,
  },
  props: {
    // bc_id: {
    //   // type: String,
    //   default: "",
    // },
  },
  data() {
    return {
      dataSource: [],
      columns,
      sizeList: ["10", "20", "30", "40"], //一页能显示条数
      pageSize: 10, //当前页显示多少条
      current: 1, //当前页
      total: 0, //总条数,在获取后台数据时将数组的length赋值给total
      data: [],
    };
  },
  created() {},
  mounted() {
    // console.log("bc_id", this.bc_id);
    // this.getUserLog(this.bc_id);
    this.getUserLog("/api/setSelf/update");
  },
  watch: {},
  methods: {
    getUserLog(bc_id) {
      // ulBase
      let param = { ulBase: bc_id };
      let orderParam = ["ulBase"];
      const params = {
        ...param,
        verify: this.$verify(param, orderParam),
      };

      GetuserLog(params).then((res) => {
        console.log(res);
        this.data = res.data;
        this.total = this.data.length;
        this.getShowSource();
      });
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
    },
    //分页页数的改变
    pageNumberChange(current, size) {
      //   console.log("分页页数的改变", current, size);
      this.current = current;
      this.pageSize = size;
      this.getShowSource();
    },
    //  分页显示条数的变化
    sizeChange(current, size) {
      //   console.log("分页显示条数的变化", current, size);
      this.current = current;
      //   this.current = 1;
      this.pageSize = size;
      this.getShowSource();
    },
    // 实现分页效果
    getShowSource() {
      const data = this.data;
      let keyValue = 0;
      this.totle = data.length;
      //   console.log(this.totle);
      for (let i = 0; i < this.total; i++) {
        keyValue = keyValue + 1;
        let key = { key: keyValue };
        data[i] = Object.assign(data[i], key);
      }
      let start = this.pageSize * this.current - this.pageSize;
      let end = this.pageSize * this.current;
      let dataSource = data.slice(start, end);
      this.dataSource = dataSource;

      this.$set(this.dataSource, [], dataSource);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-tooltip-inner {
  min-width: 500px !important;
}
/deep/.ant-tooltip-content {
  min-width: 500px !important;
}
</style>