// 个性化设置编辑页面
<template>
  <div class="PersonalizedEdit">
    <!-- 组织架构 -->
    <div class="left">
      <div class="left_title">
        <a-tooltip placement="right">
          <template slot="title">
            <span>选择使用企业(必选)</span>
          </template>
          选择使用企业(必选)
        </a-tooltip>
      </div>
      <!-- 查看单选 -->
      <div>
        <div :style="{ borderBottom: '1px solid #E9E9E9' }">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
            全部
          </a-checkbox>
        </div>
        <a-checkbox-group :options="options" v-model="value" @change="onChange">
          <span slot="label" slot-scope="{ bc_name }" class="value">
            <a-tooltip placement="right">
              <template slot="title">
                <span> {{ bc_name }}</span>
              </template>
              {{ bc_name }}
            </a-tooltip>
          </span>
        </a-checkbox-group>
      </div>
      <div></div>
    </div>
    <div class="right">
      <ViewEdit :bcIds="bcIds" />
    </div>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import ViewEdit from "./ViewEdit.vue";
export default {
  name: "PersonalizedEdit",
  components: { ViewEdit },
  props: {
    bc_id: {
      // type: String,
      default: "",
    },
  },
  data() {
    return {
      CompaniesList: [],
      options: [],
      checkAll: false,
      indeterminate: true,
      value: [],
      bcIds: [],
    };
  },
  created() {
    this.GetCompanies();
    this.value.push(this.bc_id);
    this.bcIds = this.value;
  },
  mounted() {},
  watch: {
    value(newValue) {
      this.bcIds = newValue;
    },
  },
  methods: {
    // 获取分公司列表
    GetCompanies() {
      GetCompanies().then((res) => {
        this.CompaniesList = res.data;
        this.CompaniesList.forEach((el) => {
          this.options.push({ value: el.bc_id, bc_name: el.bc_name });
        });
      });
    },
    onChange(checkedValues) {
      // console.log("checked = ", checkedValues);
      this.bcIds = checkedValues;
      this.indeterminate =
        !!checkedValues.length && checkedValues.length < this.options.length;
      this.checkAll = checkedValues.length === this.options.length;
    },
    // 全选反选
    onCheckAllChange(e) {
      // console.log(e.target.checked, this.options);
      let options = [];
      this.options.forEach((el) => {
        options.push(el.value);
      });
      Object.assign(this, {
        value: e.target.checked ? options : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.PersonalizedEdit {
  display: flex;
}
.left {
  width: 12%;
  //   background-color: pink;
  text-align: left;
  .edit {
    width: 100px;
    height: 50px;
    font-size: 24px;
    color: blue;
    cursor: pointer;
  }
  .left_title {
    width: 100%;
    border: 1px solid #d9d9d9;
    margin-top: 10px;
    background-color: #eef2f8;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  /deep/ .ant-checkbox-wrapper {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-top: none;
    height: 40px;
    line-height: 40px;
    position: relative;
  }
  /deep/.ant-checkbox-group-item {
    padding-left: 10px;
    border-top: none;
  }
  /deep/ .value {
    width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
  }
}
.right {
  text-align: left;
  width: 88%;
  padding-left: 20px;
  // background-color: pink;
}
</style>