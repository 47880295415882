// PersonalizedView  个性化设置查看页面
<template>
  <div class="PersonalizedView">
    <!-- 查看 -->
    <div class="PersonalizedSet_view" v-if="!showEdit">
      <!-- 组织架构 -->
      <div class="left">
        <div
          class="edit"
          @click="editclick"
          v-if="isShowBtns.indexOf('PersonalizedView-/api/setSelf/update') > -1"
        >
          <a-button type="primary"> 编辑 </a-button>
        </div>
        <div class="left_title">组织架构</div>
        <!-- 查看单选 -->
        <div>
          <a-radio-group
            :value="RadioValue"
            button-style="solid"
            @change="CompaniesListChange"
          >
            <a-radio-button
              v-for="item in CompaniesList"
              :key="item.bc_id"
              :value="item.bc_id"
            >
              <a-tooltip placement="right">
                <template slot="title">
                  <span> {{ item.bc_name }}</span>
                </template>
                {{ item.bc_name }}
              </a-tooltip>
            </a-radio-button>
          </a-radio-group>
        </div>
        <div></div>
      </div>
      <div class="right">
        <div
          class="amendantRecord"
          @click="changeRecord()"
          v-if="
            isShowBtns.indexOf('PersonalizedView-/api/userLog/selectByUlBase') >
            -1
          "
        >
          <a-button type="primary"> 查看修改记录 </a-button>
        </div>
        <ViewSet :bc_id="bc_id" :showEdit="showEdit" />
      </div>
    </div>
    <!-- 编辑 -->
    <div><PersonalizedEdit v-if="showEdit" :bc_id="bc_id" /></div>
    <!-- 修改记录 -->

    <a-modal
      v-model="visibleRecord"
      title="个性化设置修改记录"
      @ok="handleOkRecord"
      :footer="null"
      width="800px"
    >
      <!-- <AmendantRecord v-if="visibleRecord" :bc_id="bc_id" />
       -->
      <AmendantRecord v-if="visibleRecord" />
    </a-modal>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import PersonalizedEdit from "./PersonalizedEdit.vue";
import ViewSet from "./ViewSet.vue";
import AmendantRecord from "./AmendantRecord.vue";
export default {
  name: "PersonalizedView",
  components: { PersonalizedEdit, ViewSet, AmendantRecord },
  data() {
    return {
      showEdit: false,
      CompaniesList: [],
      bc_id: "",
      RadioValue: "",
      visibleRecord: false,
      isShowBtns: [],
    };
  },
  created() {
    this.GetCompanies();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  watch: {},
  provide() {
    return { editclick: this.editclick };
  },
  methods: {
    // 获取分公司列表
    GetCompanies() {
      GetCompanies().then((res) => {
        this.CompaniesList = res.data;
        this.bc_id = this.CompaniesList[0].bc_id;
        this.RadioValue = this.CompaniesList[0].bc_id;
      });
    },
    // 点击单选按钮
    CompaniesListChange(event) {
      // console.log(event.target.value, event.target);
      this.bc_id = event.target.value;
      this.RadioValue = event.target.value;
      // console.log("bc_id", this.bc_id);
    },
    // 点击编辑按钮
    editclick() {
      this.showEdit = !this.showEdit;
      // console.log("edit", this.showEdit);
    },
    // 查看修改记录
    changeRecord(id) {
      this.visibleRecord = true;
    },
    handleOkRecord(e) {
      this.visibleRecord = false;
    },
  },
};
</script>

<style lang="less" scoped>
.PersonalizedView {
  width: 100%;
  height: 100%;
}

.PersonalizedSet_view {
  display: flex;
  width: 100%;
  height: 100%;
  .left {
    width: 12%;
    //   width: 200px;
    text-align: left;
    .edit {
      width: 100px;
      height: 40px;
      font-size: 20px;
      color: blue;
      cursor: pointer;
    }
    .left_title {
      //   border: 1px solid gray;
      border: 1px solid #d9d9d9;
      // margin-top: 5px;
      background-color: #eef2f8;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
    }
    .ant-radio-button-wrapper {
      width: 100%;
      border-top: none;
      height: 40px;
      line-height: 40px;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-flex;
      border-left: 1px solid #d9d9d9;
    }
  }
  .right {
    width: 88%;
    // background-color: pink;
    text-align: left;
    line-height: 30px;
    padding-left: 20px;
    font-size: 14px;
    .amendantRecord {
      width: 100%;
      height: 40px;
      font-size: 20px;
      color: blue;
      text-align: right;
      cursor: pointer;
    }
    .colorGray {
      color: gray;
      padding-left: 10px;
    }
    .right_title {
      font-size: 20px;
    }
  }
}
</style>